<template>
  <div class="select-express">
    <div class="express-tag">
      <span v-for="item in common" :key="item.id" :class="{ checked: express.includes(item.id) }"
        @click="handleChange(item.id)">
        {{ item.name }}
      </span>
      <Button v-if="add" class="add-btn" type="primary" size="small" @click="show = true">+增加</Button>
    </div>



    <kdx-modal-frame v-model="show" :title="'新增' + addName" :width="450" @on-cancel="show = false" @on-ok="handleOk">
      <Form ref="form_info" :model="model" :rules="rules" :label-width="120" class="add-modal">
        <FormItem :label="addName + '名称：'" prop="name">
          <kdx-rr-input type="text" v-model="model.name" :placeholder="addName + '名称'" class="width-250"
            :appendSlot="false">
          </kdx-rr-input>
        </FormItem>
      </Form>
    </kdx-modal-frame>
  </div>
</template>

<script>
export default {
  name: "SelectExpress",
  props: {
    addName: {
      type: String,
      default: "分类"
    },
    value: {
      type: [String, Number, Array],
      required: true
    },
    common: {
      type: Array,
      default: () => [],
    },
    add: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      express: [],
      show: false,
      model: {
        name: '',
      },
      rules: {
        name: [{
          required: true,
          message: '名称必填'
        }],
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.express = this.value.map(e => e * 1);
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    handleOk() {
      this.$refs['form_info'].validate(valid => {
        if (valid) {
          this.$emit("addCategory", {
            name: this.model.name, callback: () => {
              this.show = false;
            }
          });
        }
      });

    },
    handleChange(value) {
      if (!this.express.includes(value)) {
        this.express.push(value);
      } else {
        this.express.splice(this.express.indexOf(value), 1);
      }
      this.$emit("update:value", this.express);
      this.$emit("change", this.express);
    }
  }
};
</script>

<style scoped lang="scss">
.select-express {
  width: 100%;
  padding: 4px 0;

  >.express-tag {
    /* margin-top: 10px; */
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    >span {
      @include font-14-20;
      color: $text-second;
      padding: 1px 7px;
      border: 1px solid $border-color;
      border-radius: 2px;
      margin-right: 10px;
      cursor: pointer;
      display: inline-block;
      height: 24px;
      user-select: none;

      &:last-child {
        margin-right: 0;
      }
    }

    >.checked {
      background-color: $brand-color;
      border-color: $brand-color;
      color: #ffffff;
    }

    .add-btn {
      display: inline-block;
    }
  }
}

.add-modal {
  padding-top: 20px;
}
</style>
