<template>
  <div class="level">
    <kdx-form-title content="基本信息"></kdx-form-title>
    <!-- <FormItem label="商品类型：" prop="type">
            <multi-select v-model="model_type"></multi-select>
        </FormItem> -->
    <FormItem label="商品标题：" prop="title" v-error-item.title>
      <Input v-model="model_title" type="text" :rows="1" autosize maxlength="70" show-word-limit
        class="width-430 limit-input" placeholder="请输入商品标题" />
    </FormItem>
    <!-- <FormItem label="商品副标题：" prop="sub_name">
      <Input v-model="model_sub_name" type="textarea" :rows="1" autosize maxlength="80" show-word-limit
        class="width-430 limit-input" placeholder="请输入商品副标题" />
    </FormItem>
    <FormItem label="商品描述：" prop="short_name" v-error-item.short_name>
      <Input v-model="model_short_name" type="textarea" maxlength="16" show-word-limit class="width-430 limit-input"
        placeholder="请输入商品描述" />
    </FormItem> -->
    <FormItem label="商品主图：" prop="thumb" v-error-item.thumb>
      <kdx-image-video type="image/*" :defaultList.sync="model_thumb_list" :limit="1" :action="$action"
        @change="thumbChange"></kdx-image-video>
    </FormItem>
    <FormItem v-if="model_type != 3" label="商品分类：" prop="category_id" v-error-item.category_id>
      <select-tab :common="categoryList" :value.sync="model_category_id" @change="categoryChange" style="width: 450px;"
        @addCategory="addCategory" />
    </FormItem>

    <FormItem v-if="model_type != 3" label="商品品牌：" prop="brand" v-error-item.brand>
      <select-tab :common="brandList" :value.sync="model_brand" @change="brandChange" style="width: 450px;"
        @addCategory="addBrand" addName="品牌" />
    </FormItem>

    <FormItem label="商品详情：" prop="content" v-error-item.content>
      <wang-editor v-model="model_content"></wang-editor>
    </FormItem>

    <!--收起更多基本信息设置-->
    <!-- <collapse-panel name="展开更多基本信息设置" expand-name="收起更多基本信息设置" style="padding-top: 0">
      <template>
        <FormItem label="商品排序：" prop="sort_by">
          <Input v-model.number="model_sort_by" maxlength="4" class="width-160" placeholder="请输入" />
          <kdx-hint-text content="最多4位数字, 数字越大, 排名越靠前, 默认排序为创建时间"></kdx-hint-text>
        </FormItem>
        <FormItem prop="ext_field.show_sales" class="r-form-item-checkbox" label="显示销量：">
          <RadioGroup v-model="model_ext_field.show_sales">
            <Radio label="1">
              <span>是</span>
            </Radio>
            <Radio label="0">
              <span>否</span>
            </Radio>
          </RadioGroup>
          <div class="flex">
            <kdx-hint-text content="显示销量=实际销量+虚拟销量">
              <kdx-hint-tooltip type="image" :image="require('@/assets/image/example/shop_sale.png')"></kdx-hint-tooltip>
            </kdx-hint-text>
          </div>
        </FormItem>
        <FormItem label="虚拟销量：" prop="sales">
          <kdx-rr-input number :fixed="0" :max-value="999999999" v-model="model_sales" class="width-160"
            placeholder="请输入" />
        </FormItem>
        <FormItem label="商品备注：">
          <Input v-model="model_ext_field.note" type="textarea" class="width-430 limit-input" placeholder="请输入" />
          <kdx-hint-text content="超链接需要以http/https开头，多个需要换行。"></kdx-hint-text>
        </FormItem>
      </template>
    </collapse-panel> -->
  </div>
</template>

<script>
// import CollapsePanel from '@/components/little/CollapsePanel';
// import MultiSelect from './MultiSelect'
// import TreeSelect from '@/components/tree-select/index';
import SelectTab from '@/components/SelectTab.vue';
import WangEditor from '@/components/wangEditor.vue';
import { modelMap, stateMap } from './binders.js';
import { mapState } from "vuex";

export default {
  components: {
    WangEditor, SelectTab,
    // CollapsePanel,
    // MultiSelect,
    // TreeSelect,
  },
  data() {
    return {
      categoryList: [],
      brandList: [],
    };
  },

  computed: {
    /**
     * 双向绑定vuex/goodAddEdit.js/state/model
     * 使用方法this.model_${propsName}
     */
    ...mapState("goodAddEdit", {
      is_activity_goods: state => state?.model?.is_activity_goods == 1 ? true : false,
      goodsDecorate: state => state?.goodDecrate,
    }),
    ...modelMap(),
  },
  created() {
    this.getCategory();
    this.getBrand();
  },
  methods: {
    async getBrand() {
      const result = await this.$api.goods.brandlist();
      this.brandList = result;
    },
    async getCategory() {
      const result = await this.$api.goods.categorylist();
      this.categoryList = result;
    },
    async addBrand({ name, callback }) {
      await this.$api.goods.brandadd({ name }, { toast: 1 });
      callback();
      this.getBrand();
    },
    //增加分类
    async addCategory({ name, callback }) {
      await this.$api.goods.categoryadd({ name }, { toast: 1 });
      callback();
      this.getCategory();
    },
    brandChange() {
      this.$emit('validate', 'brand');
    },
    categoryChange() {
      this.$emit('validate', 'category_id');
    },
    thumbChange(val) {
      this.model_thumb = val.map(e => e.id).join(',');
      this.$emit('validate', 'thumb');
    }
  }
};
</script>

<style lang="scss" scoped>
.ivu-tooltip-popper.ivu-tooltip-light[x-placement] {
  padding: 0;

  .ivu-tooltip-content {
    .ivu-tooltip-inner {
      padding: 6px;
    }

    .ivu-tooltip-arrow {
      display: none;
    }
  }
}

.swiper-img {
  /deep/ul.image {
    margin-bottom: -10px;

    li.img-li {
      margin-bottom: 10px;
    }
  }

  /deep/.slide-image .slide-image-add {
    margin-bottom: 0 !important;
  }
}

.width-250 {
  width: 250px;
}

.show-img.marginL-120 {
  /deep/ .add {
    background: #fff;
  }
}

.r-form-item-checkbox .nest-box {
  /deep/ .ivu-form-item-error-tip {
    margin-left: 120px;
  }
}

.r-form-item-checkbox .nest-box {
  .telephoneLimit /deep/+.ivu-form-item-error-tip {
    margin-left: 0px;
  }
}

.labelLink {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  box-sizing: border-box;
  color: $text-first;
  padding: 8px 0 7px 0;
  line-height: 20px;
  padding-right: 10px !important;
  width: 120px;
}
</style>
