import { render, staticRenderFns } from "./wangEditor.vue?vue&type=template&id=5239dffc&scoped=true&lang=html"
import script from "./wangEditor.vue?vue&type=script&lang=js"
export * from "./wangEditor.vue?vue&type=script&lang=js"
import style0 from "./wangEditor.vue?vue&type=style&index=0&id=5239dffc&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5239dffc",
  null
  
)

export default component.exports