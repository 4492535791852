<template>
	<div class="level">
		<kdx-form-title content="交易设置"></kdx-form-title>
		<FormItem label="规格设置：" prop="has_option" class="r-form-item-checkbox">
			<!-- <RadioGroup class="flex" v-model="model_has_option">
				<Radio label="0" :disabled="is_activity_goods">
					<span>单规格</span>
				</Radio>
			</RadioGroup> -->

			<!--单规格和多规格切换-->
			<div class="nest-box" style="padding: 0;margin-top: 0;">
				<!--单规格-->
				<div class="single-one" style="padding-top:30px;" v-if="model_has_option === '0'">
					<FormItem label="原价：" prop="price" v-error-item.price>
						<kdx-rr-input v-model="model_price" placeholder="请输入" :disabled="is_activity_goods || model_type == 3" number
							:fixed="2" :min-value="0" :max-value="9999999.99" class="width-172"><span
								slot="append">元</span></kdx-rr-input>
					</FormItem>
					<FormItem prop="cost_price">
						<template #label>
							<div>
								<span>优惠价</span>
								<kdx-hint-tooltip v-if="model_type == 3">
									输入后，组合中的商品会按比例自动计算优惠价
								</kdx-hint-tooltip>
								<span>：</span>
							</div>
						</template>
						<kdx-rr-input v-model="model_cost_price" placeholder="请输入" number :fixed="2" :min-value="0"
							:max-value="9999999.99" class="width-172"><span slot="append">元</span></kdx-rr-input>
					</FormItem>
				</div>
				<div style="padding-bottom:30px;">
					<FormItem class="stock" label="库存：" prop="stock" style="margin-bottom: 0;display: flex" v-error-item.stock>
						<template #label>
							<div>
								<span>库存</span>
								<kdx-hint-tooltip v-if="model_type == 2">
									导入卡券Excel表自动计算
								</kdx-hint-tooltip>
								<span>：</span>
							</div>
						</template>
						<kdx-rr-input v-model="model_stock" placeholder="请输入库存" :disabled="is_activity_goods || model_type == 2"
							number :fixed="0" :max-value="999999999" class="width-172"></kdx-rr-input>
					</FormItem>
				</div>
				<div style="padding-bottom:30px;" v-if="model_type == 2 && $route.query.operate == 'edit'">
					<FormItem label="卡券：" style="margin-bottom: 0;display: flex" >
						<template #label>
							<div>
								<span>卡券</span>
								<kdx-hint-tooltip>
									导入卡券数据为新增数据
								</kdx-hint-tooltip>
								<span>：</span>
							</div>
						</template>
						<Upload :action="$baseUrl + 'shopapi/shop/importexcel'" accept=".xls" :max-size="100 * 1024"
							:before-upload="() => showUploadList = true" :show-upload-list="showUploadList"
							:data="{ access_token: $store.state.token, sid: $route.query.id }" 
							:on-success="handleSuccess" :on-exceeded-size="() => this.$Message.error(`文件大小不能超过100MB`)">
							<Button type="primary">+导入卡券Excel表</Button>
						</Upload>
						<a href="https://zzy2.zhangzhengyun.com/down/shop/card_coupon.xls">标准卡券Excel模板下载</a>
					</FormItem>
				</div>
			</div>
		</FormItem>
		<FormItem label="库存设置：" prop="reduction_type" class="r-form-item-checkbox">
			<RadioGroup v-model="model_reduction_type" style="margin-bottom: -10px">
				<div class="inventory-settings">
					<Radio label="0" :disabled="isSeckill">
						<span>下单减库存</span>
					</Radio>
					<!-- <Button v-show="!isMoreInventorySetting" type="text" @click="getMoreInventorySetting"
						style="display: block;font-size: 12px;height: inherit;padding-top: 10px">更多选项
						<span class="iconfont icon-line-right"></span>
					</Button>
					<kdx-hint-text v-show="isMoreInventorySetting" errContent="恶意占用库存风险">买家提交订单, 扣减库存数量, 可能会存在
					</kdx-hint-text> -->
				</div>
				<!-- <div class="inventory-settings" v-show="isMoreInventorySetting">
					<Radio label="1" :disabled="isSeckill">
						<span>付款减库存</span>
					</Radio>
					<kdx-hint-text errContent="超卖风险">买家支付成功, 扣减库存数量, 可能会存在</kdx-hint-text>
				</div>
				<div class="inventory-settings" v-show="isMoreInventorySetting">
					<Radio label="2" :disabled="isSeckill">
						<span>永不减库存</span>
					</Radio>
					<kdx-hint-text>
						无论在下单还是在支付成功,
						该商品永远不会扣减库存数量
					</kdx-hint-text>
				</div> -->
			</RadioGroup>
		</FormItem>
	</div>
</template>

<script>
// import CollapsePanel from '@/components/little/CollapsePanel';
import { modelMap, stateMap } from './binders.js';
// import RemindLabel from '@/components/little/RemindLabel';
import { mapState } from "vuex";
export default {
	components: {
		// CollapsePanel,
		// RemindLabel,
	},
	props: {
		multiple: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			showUploadList: true,
		};
	},
	computed: {
		...modelMap(),
		...stateMap([
			'rules',
			'isThumbAddSlide',
			'isShowVideo',
			'shopLabel',
			'isMoreInventorySetting',
			'actionType',
		]),
		...mapState("goodAddEdit", {
			is_activity_goods: state => state?.model?.is_activity_goods == 1 ? true : false,
			// 获取虚拟卡密库数据
		}),
		isSeckill() {
			return this.$store.state.goodAddEdit.model?.is_seckill == 1;
		},
	},
	watch: {
		model_type: {
			handler(val) {
				//虚拟物品库存设置
				if (val == 2 && this.model_stock == '') {
					this.model_stock = 0;
				}
				if (val != 2 && this.model_stock == 0) {
					this.model_stock = '';
				}
			},
			immediate: true,
		}
	},
	methods: {

		handleSuccess(response, file) {
			const { code, msg, data } = response;
			if (code == 0) {
				this.model_stock = data.inventory;
				this.$Message.success(msg);
			} else {
				this.$Message.error(msg);
			}
			this.showUploadList = false;
		},
		formError(val) {
			this.$emit('form-error', val);
		},
		// 更多库存设置
		getMoreInventorySetting() {
			this.isMoreInventorySetting = true;
		},
		// 是否支持发票
		invoiceChange(value) {
			if (this.$store.state.config.business.invoice == '0') {
				// 支持发票时提示
				if (value === '1') {
					this.$Modal.confirm({
						title: '您还未开启支持发票类型',
						content:
							'购买商品支持开发票时，需要在设置 [交易设置-发票设置] 中选择支持发票类型',
						okText: '去设置',
						onOk: () => {
							// 跳转到发票管理页面
							let routeData = this.$router.resolve({
								path: '/setting/business/index'
							});
							window.open(routeData.href, '_blank');
						},
						onCancel: () => {
							this.model_ext_field.invoice = '0';
						}
					});
				}
			}
		},
	}
};
</script>

<style lang="scss" scoped>
.tip-item {
	display: inline-block;
	margin-left: -10px;

	/deep/ .hint-text {
		padding-top: 0;
	}
}

.goods-form-input {

	/deep/.ivu-input-wrapper {
		&:hover {
			.ivu-input {
				border-color: $border-color;
			}
		}

		.ivu-input {
			&:disabled {
				background-color: #FFFFFF;
			}
		}

		.ivu-input-group-prepend,
		.ivu-input-group-append {
			background-color: #FFFFFF;
		}
	}
}

.nest-box-form {
	/deep/ .ivu-form-item-error-tip {
		padding-left: 120px;
	}
}

.stock {
	/deep/ label {
		display: flex;
		justify-content: flex-end;
	}
}
</style>
