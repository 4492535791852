
import store from '@/store';

export const modelMap = () => { //v-model绑定vuex/goodAddEdit模块下的state/model中的参数
    let dataObj = {};
    let model = store.state.goodAddEdit.model;
    for (let key in model) {
        dataObj[`model_${key}`] = {
            get() {
                return store.state.goodAddEdit.model[key];
            },
            set(val) {
                store.commit('goodAddEdit/setModelProps', {
                    [key]: val
                });
            }
        };
    }
    return dataObj;
};
export const stateMap = (prop) => { //v-model绑定vuex/goodAddEdit模块下的state中的参数
    let obj = {};
    if (typeof prop === 'string') {
        obj = {
            [prop]: {
                get() {
                    return store.state.goodAddEdit[prop];
                },
                set(val) {
                    store.commit(`goodAddEdit/set${prop.replace(/^./, res => {
                        return res.toUpperCase();//首字母大写
                    })}`, val);
                }
            }

        };
    } else if (prop instanceof Array) {
        prop.forEach(item => {
            obj[item] = {
                get() {
                    return store.state.goodAddEdit[item];
                },
                set(val) {
                    store.commit(`goodAddEdit/set${item.replace(/^./, res => {
                        return res.toUpperCase();//首字母大写
                    })}`, val);
                }
            };
        });
    }
    return obj;
};
