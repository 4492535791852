<template>
    <div class="goods-type">
        <kdx-form-title content="商品类型"></kdx-form-title>
        <FormItem label="" prop="type">
            <multi-select v-model="model_type"></multi-select>
        </FormItem>
    </div>
</template>

<script>
import MultiSelect from './MultiSelect';
import { modelMap } from './binders.js';

export default {
    components: {
        MultiSelect,
    },
    computed: {
        /**
         * 双向绑定vuex/goodAddEdit.js/state/model
         * 使用方法this.model_${propsName}
         */
        ...modelMap(),
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.goods-type {
    /deep/ .ivu-form-item-content {
        margin-left: 0 !important;
    }
}
</style>
