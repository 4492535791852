<template>
	<div class="good-add-base">
		<Form ref="form" @on-validate='onValidate' :model="goodAddEditModel" :rules="$store.state.goodAddEdit.rules"
			:label-width="120">
			<!--商品类型-->
			<goods-type />
			<!--基本信息-->
			<basic-info @validate="k => validateField(k)"></basic-info>
			<!-- 商品组合列表 -->
			<shop-list v-if="model_type == 3" v-model="model_goods"></shop-list>
			<!--交易设置-->
			<transaction-settings :multiple="model_type == 3"></transaction-settings>
			<!--物流设置-->
			<!-- <logistics-settingsms /> -->
			<!--商品状态-->
			<commodity-status :form_status="form_status"></commodity-status>
		</Form>
	</div>
</template>

<script>
import ShopList from './components/ShopList';
import BasicInfo from './components/BasicInfo';
import GoodsType from './components/GoodsType';
import TransactionSettings from './components/TransactionSettings';
// import LogisticsSettingsms from '../../add_simple/base/components/LogisticsSettings';
import CommodityStatus from './components/CommodityStatus';

import { modelMap } from './components/binders.js';

export default {
	name: "index",
	components: {
		GoodsType,
		BasicInfo,
		TransactionSettings,
		CommodityStatus,
		ShopList
		// LogisticsSettingsms,
	},
	props: {
		curTab: {
			type: [String, Number],
			default: ''
		},
		form_status: {
			type: [String, Number],
			default: -1
		}
	},
	computed: {
		...modelMap(),
		goodAddEditModel() {
			return this.$store.state.goodAddEdit.model;
		},
	},
	methods: {
		resetFields() {
			this.$refs['form'].resetFields();
		},
		validateField(key) {
			setTimeout(() => {
				this.$refs['form'].validateField(key);
			}, 200);
		},
		onValidate(prop, status) {
			this.cacheError = {
				...this.cacheError,
				[prop]: status
			};
		},
		validate() {
			return new Promise(resolve => {
				this.$refs['form'].validate(valid => {
					resolve(valid);
					this.$nextTick(() => {
						if (!valid) {
							for (let k in this.cacheError) {
								if (!this.cacheError[k]) {
									// this.$focusError(k);
									break;
								}
							}
						}
					});
				});
			});
		}
	},
	beforeDestroy() {

	}
};
</script>

<style scoped lang="scss">
.good-add-base {
	background: rgb(255, 255, 255);
	margin-top: 20px;
	padding-top: 40px;
	min-height: 100%;

	// 问号提示框
	/deep/ .iconicon-test {
		vertical-align: -1px;
	}

	/deep/ .ivu-form {
		padding: 0 40px;

		.level {
			padding-bottom: 30px;

			/deep/ .ivu-form-item {
				margin-bottom: 30px;
			}
		}

		.nest-box {
			padding: 30px 20px;
			margin-top: 10px;
			background-color: $background-color;

			.single-one {
				display: flex;
				flex-wrap: wrap;

				.ivu-form-item {
					display: flex !important;
				}
			}
		}

		// 库存设置
		.inventory-settings {
			padding-bottom: 20px;

			.hint-text {
				padding-left: 25px;
			}

			.icon-line-right {
				color: $brand-color;
				font-size: 12px;
			}
		}

		// 运费设置
		.freight-setting {
			.ivu-btn-text {
				padding-left: 20px;
				font-size: 12px;
			}

			.ivu-form-item {
				margin-bottom: 0;
			}

			// 规则提示
			.ivu-form-item-error-tip {
				position: inherit;
			}
		}
	}

	/deep/ .collapse-panel {
		.ivu-collapse-header {
			display: inline-block;
		}
	}
}
</style>
