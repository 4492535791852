import { render, staticRenderFns } from "./GoodsType.vue?vue&type=template&id=bc1114ac&scoped=true"
import script from "./GoodsType.vue?vue&type=script&lang=js"
export * from "./GoodsType.vue?vue&type=script&lang=js"
import style0 from "./GoodsType.vue?vue&type=style&index=0&id=bc1114ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc1114ac",
  null
  
)

export default component.exports