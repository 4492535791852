<template>
	<div class="multi-select">
		<!--单选-->
		<RadioGroup v-model="type" @input="handleInput">
			<Radio label="1" border :disabled="isDisabled('1')">
				<div class="tit">实体商品</div>
				<div class="desc">物流发货</div>
				<svg id="icon-yes1" class="icon" viewBox="0 0 1024 1024">
					<path d="M0 1024h1024V0z" fill="#2D8CF0"></path>
					<path
						d="M608.548571 893.074286l-170.788571-159.451429 34.011429-45.348571 136.411428 125.074285 284.525714-267.337142 34.011429 45.348571-318.171429 301.714286z"
						fill="#FFFFFF"></path>
				</svg>

			</Radio>
			<Radio label="2" border :disabled="isDisabled('2')">
				<div class="tit">虚拟商品</div>
				<div class="desc">无需物流</div>
				<svg id="icon-yes1" class="icon" viewBox="0 0 1024 1024">
					<path d="M0 1024h1024V0z" fill="#2D8CF0"></path>
					<path
						d="M608.548571 893.074286l-170.788571-159.451429 34.011429-45.348571 136.411428 125.074285 284.525714-267.337142 34.011429 45.348571-318.171429 301.714286z"
						fill="#FFFFFF"></path>
				</svg>
			</Radio>
			<Radio label="3" border :disabled="isDisabled('3')">
				<div class="tit">组合商品</div>
				<div class="desc"></div>
				<svg id="icon-yes1" class="icon" viewBox="0 0 1024 1024">
					<path d="M0 1024h1024V0z" fill="#2D8CF0"></path>
					<path
						d="M608.548571 893.074286l-170.788571-159.451429 34.011429-45.348571 136.411428 125.074285 284.525714-267.337142 34.011429 45.348571-318.171429 301.714286z"
						fill="#FFFFFF"></path>
				</svg>
			</Radio>
		</RadioGroup>
		<kdx-hint-text v-if="!$route.query.id">商品类型在商品保存后无法修改，请谨慎选择。</kdx-hint-text>
	</div>
</template>

<script>
export default {
	name: 'MultiSelect',
	props: {
		value: {
			type: [Number, String],
			required: true,
		},
	},
	data() {
		return {
			type: '',
		};
	},
	computed: {
	},
	watch: {
		value: {
			handler(value) {
				this.type = value;
			},
			immediate: true,
		},
	},
	mounted() {
	},
	methods: {
		isDisabled() {
			console.log(!!this.$route.query.id);
			return !!this.$route.query.id;
		},
		handleInput(val) {
			this.$emit('input', val);
		},
	},
};
</script>

<style scoped lang="scss">
.multi-select {
	/deep/ .ivu-radio {
		display: none;
	}

	/deep/ .ivu-radio-group {
		display: flex;
		align-items: center;
	}

	.ivu-radio-group-item {
		position: relative;
		box-sizing: border-box;
		padding-left: 0;
		padding-right: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 120px;
		height: 60px;
		font-size: 14px;
		line-height: 20px;
		border-radius: 2px;
		margin-right: 10px;

		&:last-child {
			margin-right: 0;
		}

		.tit {
			font-size: 14px;
			line-height: 20px;
			font-weight: bold;
			color: #262b30;
		}

		.desc {
			font-size: 12px;
			line-height: 16px;
			color: #636669;
			margin-top: 2px;
		}

		.icon {
			width: 24px;
			position: absolute;
			font-size: 24px;
			bottom: -2px;
			right: 0;
			color: $brand-color;
			display: none;
		}
	}

	.ivu-radio-wrapper-disabled {
		background-color: $background-color;
	}

	.ivu-radio-wrapper-checked {
		background-color: #fff;
		border-width: 2px;
		border-color: $brand-color;

		.tit {
			color: $brand-color;
		}

		.icon {
			display: inline-block;
		}
	}

	.permModel {
		display: none;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 20px;
		background: #F4F6F8;
		cursor: pointer;

		&.is_perModel {
			display: flex;
		}

		p {
			font-size: 12px;
			color: #939799;
			line-height: 20px;
			text-align: center;
			display: flex;
			justify-content: center;

			.iconfont {
				font-size: 12px;
				color: #939799;
				line-height: 20px;
			}

			&:hover {
				color: #2D8CF0;

				.iconfont:hover {
					color: #2D8CF0;
				}
			}
		}
	}
}
</style>
