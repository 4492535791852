<template>
  <kdx-content-bar ref="content_bar" :loading="loading">
    <keep-alive>
      <base-add v-if="isOk" ref="base" :curTab="value" :form_status="form_status"></base-add>
    </keep-alive>
    <template #btn>
      <Button class="default-long" @click="handleSave">保存</Button>
    </template>
  </kdx-content-bar>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseAdd from "./base/index";
import { throttle } from "@/helpers/common.js";
export default {
  name: "add",
  components: {
    BaseAdd,
  },
  data() {
    return {
      value: "base",
      id: 0,
      subShopId: '',
      operate: "", // 区分编辑还是复制
      type: "update",
      loading: false,
      isOk: false,
      lastValue: 'base',
      form_status: -1, // 3 代表仓库中进来
    };
  },
  computed: {
    ...mapState('goodAddEdit', ['model']),
    goodAddEditModel() {
      return this.$store.state.goodAddEdit.model;
    },
  },
  watch: {
    "$route.path": {
      handler() {
        this.id = this.$route.query.id;
        this.operate = this.$route.query.operate;
        this.form_status = this.$route.query.status;
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("goodAddEdit", [
      "getGoodData",
    ]),
    // 保存
    handleSave: throttle(async function () {
      const valid = await this.$refs.base.validate();
      if (!valid) return;
      if (this.id && this.operate === 'edit') {
        this.editGood();
      } else {
        this.addGood();
      }
    }, 500),

    // 新增商品
    async addGood() {
      this.loading = true;
      const { type, title, thumb, cost_price, price, stock, status, content, category_id, brand } = this.model;
      if (type == 3) {
        //组合
        const ids = [];
        const shopJson = JSON.stringify(this.goodAddEditModel.goods.map(e => {
          const { goods_num, type, title, thumb_list, thumb, goods, has_option, price, cost_price, stock, buyNum, date, discou, id, inventory, isRelease, money, pic, releaseName, typeName, view } = e;
          ids.push(id);
          return { goods_num, type, title, thumb_list, thumb, goods, has_option, price, cost_price, stock, buyNum, date, discou, id, inventory, isRelease, money, pic, releaseName, typeName, view };
        }));
        await this.$api.goods.packageadd({ title, cover: thumb, money: price, discou: cost_price, is_release: status, inventory: stock, content, shopJson, shopids: ids.join(',') }, { toast: 1 }).finally(() => {
          this.loading = false;
        });
      } else {
        const categoryIds = category_id.join(',');
        const brandIds = brand.join(',');
        await this.$api.goods.goodsadd({ title, cover: thumb, money: price, discou: cost_price, is_release: status, inventory: stock, content, type, category: categoryIds, brand: brandIds }, { toast: 1 }).finally(() => {
          this.loading = false;
        });
      }

      this.$router.go(-1);
    },
    // 修改商品信息
    async editGood() {
      this.loading = true;
      const { type, title, thumb, cost_price, price, stock, status, content, category_id, brand } = this.model;
      if (type == 3) {
        //组合
        const ids = [];
        const shopJson = JSON.stringify(this.goodAddEditModel.goods.map(e => {
          const { goods_num, type, title, thumb_list, thumb, goods, has_option, price, cost_price, stock, buyNum, date, discou, id, inventory, isRelease, money, pic, releaseName, typeName, view } = e;
          ids.push(id);
          return { goods_num, type, title, thumb_list, thumb, goods, has_option, price, cost_price, stock, buyNum, date, discou, id, inventory, isRelease, money, pic, releaseName, typeName, view };
        }));
        await this.$api.goods.packageedit({ title, cover: thumb, money: price, discou: cost_price, is_release: status, inventory: stock, content, shopJson, reviseid: this.id, shopids: ids.join(',') }, { toast: 1 }).finally(() => {
          this.loading = false;
        });
      } else {
        const categoryIds = category_id.join(',');
        const brandIds = brand.join(',');
        await this.$api.goods.goodsedit({ title, cover: thumb, money: price, discou: cost_price, is_release: status, inventory: stock, content, type, reviseid: this.id, category: categoryIds, brand: brandIds }, { toast: 1 }).finally(() => {
          this.loading = false;
        });
      }
      this.$router.go(-1);
    },

  },
  async created() {
    this.loading = true;
    if (this.$route.query.type) {
      // 设置商品类型
      this.$nextTick(() => {
        this.$store.commit('goodAddEdit/setModelProps', {
          type: this.$route.query.type
        });
      });
    }
    if (this.id) {
      // 获得商品信息
      await this.getGoodData({
        id: this.id,
        operate: this.operate,
        stype: this.$route.query.stype
      });
    }
    this.isOk = true;
    this.loading = false;

  },
  deactivated() {
    this.$store.commit('goodAddEdit/resetModel');
    // this.$refs.base.resetFields();
  },
  destroyed() {
    this.$store.commit('goodAddEdit/resetModel');
    // this.$refs.base.resetFields();
  }
};
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-bar {
  margin-bottom: 40px;
  padding: 0 30px;

  .ivu-tabs-tab {
    padding: 12px 30px;
    @include font-18-25-bold;

    .icon-tishi-prompt {
      position: absolute;
      right: 10px;
      color: $danger-color;
      font-size: 18px;
    }
  }
}

/deep/ .ivu-form-item-label {
  padding-right: 10px !important;
}
</style>
